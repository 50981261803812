/* Main Palette */
$fun-mainGreen: rgb(47, 218, 144); // #2fda90;
$fun-lightGreen: rgb(112, 245, 165); // #70f5a5;
$fun-white: rgb(255, 255, 255); // #ffffff;

/* Secundary Palette */
$fun-blue: rgb(46, 126, 255); // #2e7eff;
$fun-red: rgb(236, 9, 9); // #ec0909;
$fun-yellow: rgb(250, 190, 87); // #fabe57;
$fun-green: rgb(28, 182, 9); // #1cb609;
$fun-darkBlue: rgb(34, 94, 191); // #225ebf;
$fun-darYellow: rgb(255, 191, 82); // #ffbf52;

/* Energy Palette */
$fun-eolicColor: rgb(126, 167, 191); // #7ea7bf;
$fun-hidraulicColor: rgb(10, 205, 217); // #0acdd9;
$fun-solarColor: rgb(254, 161, 0); // #fea100;
$fun-biomassColor: rgb(221, 111, 89); // #dd6f59;

/* Background Pallete */
$fun-veryLightBG: rgb(246, 248, 248); // #f6f8f8;
$fun-lightBG: rgb(246, 249, 250); // #f6f9fa;
$fun-darkBG: rgb(34, 39, 54); // #222736;

/* Tertiary Pallete */
$fun-darkGreen: rgb(95, 207, 120); // #5fcf78;
$fun-offGreen: rgb(131, 175, 170); // #83afaa;
$fun-washedGreen: rgb(168, 187, 185); // #a8bbb9;
$fun-deepBlue: rgb(37, 93, 183); // #255db7;

/*Avatar BG template*/
$fun-avatarGreen: rgb(108, 229, 128); // #6ce580;
$fun-avatarRed: rgb(232, 131, 125); // #e8837d;
$fun-avatarBlue: rgb(140, 170, 217); // #8caad9;
$fun-avatarBrown: rgb(232, 193, 125); // #e8c17d;

/* Grey Pallete */
$fun-100: rgb(17, 17, 17); // #111111;
$fun-080: rgb(65, 65, 65); // #414141;
$fun-060: rgb(112, 112, 112); // #707070;
$fun-040: rgb(160, 160, 160); // #a0a0a0;
$fun-020: rgb(207, 207, 207); // #cfcfcf;
$fun-010: rgb(231, 231, 231); // #e7e7e7;
$fun-005: rgb(243, 243, 243); // #f3f3f3;

/*others*/
$fun-greenProjNav: rgb(0, 191, 64); // #00bf40;
$fun-toggleMenublack: rgb(56, 54, 65); // #383641;
$fun-alert: rgb(253, 86, 91); // #FD565B;
$fun-ingToday: rgb(24, 139, 24); // #188b18;
$fun-ingEstim: rgb(75, 210, 98); // #4bd262;
$fun-borderMovement: rgb(224, 229, 231); // #E0E5E7;
$fun-background-page: #f6f9fa;
$fun-investMovement: rgb(0, 191, 64); // #00BF40;
$fun-borderMovement:  rgb(224, 229, 231); // #E0E5E7;
$fun-questionCard: rgb(34, 94, 191); // #225EBF;
$fun-financingTag: rgb(255, 191, 82); // #FFBF52;
$fun-startsIn: rgb(255, 90, 81); // #FF5A51
$fun-future: rgb(247, 117, 110); // #F7756E
$fun-financiedF: rgb(24, 139, 24); //#6CE580
$fun-financiedC: rgb(0, 191, 64); // #4BD262
$fun-financiedP: rgb(47, 218, 144); // #00BF40



$eggplant120: rgb(41, 20, 31);  // #29141F;
$eggplant100: rgb(56, 27, 42);  // #381B2A;
$eggplant80: rgb(102, 51, 78);  // #66334E;
$eggplant70: rgb(160, 124, 141);  // #A07C8D;
$eggplant55: rgb(219, 180, 198);  // #DBB4C6;
$eggplant40: rgb(240, 201, 219);  // #F0C9DB;
$eggplant25: rgb(255, 220, 238);  // #FFDCEE;

$orange120: rgb(204, 65, 37);  // #CC4125;
$orange100: rgb(229, 73, 55);  // #E54937;
$orange80: rgb(240, 110, 50);  // #F06E32;
$orange70: rgb(246, 132, 76);  // #F6844C;

$pink120: rgb(255, 143, 145);  // #FF8F91;
$pink100: rgb(255, 169, 170);  // #FFA9AA;
$pink80: rgb(255, 194,195);  // #FFC2C3;

$ocher120: rgb(158, 153, 139);  // #9E998B;
$ocher100: rgb(184, 178, 162);  // #B8B2A2;
$ocher80: rgb(209, 202, 184);  // #D1CAB8;

$cream120: rgb(229, 216, 166);  // #E5D8A6;
$cream100: rgb(255, 240, 185);  // #FFF0B9;
$cream80: rgb(255, 246,212);  // #FFF6D4;
$cream40: rgb(255, 249, 227);  // #FFF9E3;
$cream25: rgb(255, 251, 237);  // #FFFBED;
$cream10: rgb(255, 254, 248);  // #FFFEF8;

$gray40: rgb(169, 161, 165);  // #A9A1A5;
$gray25: rgb(201, 196, 199);  // #C9C4C7;
$gray10: rgb(234, 231, 233);  // #EAE7E9;


$active: #8AEC93;
$verified: #2774E7;
$toggle: #E0CFD7;
$borderAccordion: rgba(56, 27, 42, 0.25);
$redCardTitle: #E64937;

$gradientOrange: rgb(255, 128, 77); //#FF804D;
$gradientPink: rgb(255, 140, 146); //#FF8C92;
$gradientYellow: rgb(255, 205, 62); //#FFCD3E;

$gradientRosePink: rgb(255, 163, 163); //#FFA3A3;
$gradientRoseYellow: rgb(255, 240, 185); //#FFF0B9;

$gradientOrangeOrange: rgb(255,179,96); //#FFB360;
$gradientOrangeYellow: rgb(255, 240, 185); //#FFF0B9;