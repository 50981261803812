@import "../core/colors.scss";
@import "../core/fonts.scss";

*:focus {
    outline: none !important;
}
/* .mob-general {
    .header h2 {
        padding-top: 12px !important;
    }
} */
.register {
    /* max-height: calc(100% - 94px); */
    //overflow: auto;
    .span-container {
        height: 1px !important;
        min-height: 1px !important;
        @media screen and (max-width: 991px) {
            display: none !important;
        }
        span {
            display: none !important;
        }
    }
    .select.form-input, select, option {
        width: 100%;
    }
    // label {
    //     color: $fun-060;
    //     line-height: 21px;
    // }
    select:hover {
        cursor: pointer;
    }
    .investor-steps {
        li {
            padding-left: 44px;
            position: relative;
            margin-bottom: 30px;
            h3 {
                margin-bottom: 8px;
                @extend %extrasmallInterBold;
            }
            p {
                @extend %extrasmallInterRegular;
            }
            h3::before {
                counter-increment: my-sec-counter;
                content: counter(my-sec-counter);
                background-color: $fun-blue;
                color: $fun-white;
                width: 30px;
                height: 26px;
                text-align: center;
                padding-top: 4px;
                border-radius: 50%;
                position: absolute;
                left: 0px;
                top: -4px;
                z-index: 2;
              }
        }
    }
    .checkbox {
        label {
            @extend %extrasmallInterRegular;
            color: $fun-060;
        }
    }
    .checkbox:last-child {
        margin-bottom: 0 !important;
    }
    .document-uploaded {
        position: relative;
        padding: 18px 0;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        width: 100%;
        .file {
            margin-right: 15px;
        }
        h5 {
            margin: 0;
            @extend %extrasmallInterMedium;
        }
        .grey {
            color: $fun-020;
        }
        .download {
            position: absolute;
            right: 0px;
        }
        .download:hover, .file:hover, p:hover{
            cursor: pointer;
        }
    }
    .body-content {
        .phone {
            .phone-c {
                margin-bottom: 24px;
            }
            article {
                margin-bottom: 14px;
            }
        }
        .step {
            .header {
                p {
                    padding-bottom: 40px;
                }
            }
            .body {
                .inp {
                    margin-bottom: 16px;
                }
            }
            // h2 {
            //     @extend %bigInterBold;
            //     padding-bottom: 8px;
            // }
            // p {
            //     @extend %verysmallInterRegular;
            //     color: $fun-060;
            //     span {
            //         font-weight: bold;
            //     }
            // }
            .body-info {
                @extend %extrasmallInterRegular;
                color: $fun-040;
            }
            article {
                fun-checkbox {
                    float: left;
                    margin-right: 12px;
                }
                h3 {
                    padding-top: 1px;
                    float: left;
                    margin: 0;
                    // @extend %xxsInterRegular;
                    // color: $fun-060;
                    span {
                        color: $fun-mainGreen;
                    }
                }
                div {
                    clear: both;
                }
            }
            .code-container {
                position: relative;
                p {
                    margin-top: 40px;
                }
            }
            .name-container {
                fun-input {
                    margin-bottom: 16px;
                }
            }
        }
    }
    .desktop-footer {
        align-self: flex-end;
        height: 120px;
        background-color:#FFFFFF;
        //position: absolute;
        bottom: 46px;
        left: 0;
        padding: 24px 94px;
        width: 100%;
        border-top: 1px solid $fun-010;
        .content-footer {
            position: relative;
            display: flex;
            justify-content: center;
            .back-btn {
                z-index: 1;
                position: absolute;
                left: 0;
            }
            .next-btn {
                z-index: 1;
                position: absolute;
                right: 0;
            }
            h4 {
                z-index: 444;
                padding-top: 18px;
                margin: 0;
                position: relative;
                letter-spacing: 1px;
                text-transform: uppercase;
                text-align: center;
                // color: $fun-mainGreen;
                // @extend %xxsInterBold;
            }
            // h4:hover {
            //     cursor: pointer;
            // }
        }
    }
    .mobile-footer {
        h4 {
            z-index: 444;
            margin: 24px 0 24px 0;
            letter-spacing: 1px;
            text-transform: uppercase;
            text-align: center;
            // color: $fun-mainGreen;
            // @extend %xxsInterBold;
        }
    }
    h4:hover {
        cursor: pointer;
    }
    :host::ng-deep fun-button button {
        padding: 16px 32px !important;
    }
    @media screen and (min-width: 991px) { // Pantallas grandes
        height: 100%;
        //max-height: calc(100% - 94px);
        .body-content {
            max-width: 920px;
            display: block;
            margin: auto;
            margin-top: 62px;
        }
        .mobile-footer {
            display: none;
        }
    }
    @media screen and (min-width: 991px) {
        //height: calc(100% - 94px);
        .body {
            padding-bottom: 40px;
            border-radius: 8px 8px 0px 0px;
        }
    }
    @media screen and (max-width: 991px) {
        .header {
            padding: 0px 20px 32px 20px;
            p {
                padding: 0 !important;
            }
            //background-color: $fun-lightBG;
        }
        .mobApp {
            h2 {
                margin: 0;
            }
            background: #f6f9fa;
            padding-left: 20px;
            padding-right: 20px;
        }
        .body {
            padding-left: 20px;
            padding-right: 20px;
            padding-bottom: 40px;
            padding-top: 12px;
            background: #FFFFFF;
            border-radius: 8px 8px 0px 0px;
        }
        .mobile-footer {
            position: relative;
            /* left: 14px;
            bottom: 24px;
            width: 100%;
            padding-right: 28px;
            z-index: 2; */
        }
        .desktop-footer {
            display: none;
        }
        :host::ng-deep fun-button button {
            width: 100% !important;
            margin-top: 10px;
        }
    }
    @media screen and (max-height: 800px) and (min-height: 710px) {
        .scrollable {
            height: 392px;
            overflow: auto;
        }
    }
    @media screen and (max-height: 710px) and (min-height: 638px) {
        .scrollable {
            height: 300px;
            overflow: auto;
        }
    }
    @media screen and (max-height: 638px) {
        .scrollable {
            height: 275px;
            overflow: auto;
        }
    }
    @media screen and (max-height: 850px) and (min-width: 750px) {
        .mob-general {
            height: 500px;
        }
        .scrollable {
            height: 450px !important;
            overflow: auto;
        }
    }
    @media only screen and (min-width: 992px) {
        .mob-general {
            padding-bottom: 40px;
        }
    }
}