@import "../core/colors.scss";
@import "../core/fonts.scss";

html.md {
	--ion-default-font: "Inter-Regular" !important;
}
.big-bid-box {
	display: flex;
	.big-bid {
		margin-right: 32px;
	}
}
.big-bid {
	display: flex;
	flex-direction: column;
	span {
		&:first-child {
			@extend %xxsInterBold;
			color: #a0a0a0;
			margin-bottom: 8px;
		}
		&:last-child {
			@extend %bigInterBold;
			margin-bottom: 24px;
		}
	}
}
section.content-boxed {
	//border: 1px solid #E7E7E7;
	border-radius: 4px;
	.col-5 {
		padding: 60px;
	}
	.col-6 {
		padding: 60px;
		&:last-child {
			border-left: 1px solid #cfcfcf;
		}
	}
	&.row {
		display: flex;
		justify-content: center;
		align-items: center;
	}
}
.button-content fun-button {
	margin-right: 8px;
}

// COMPONENTE DE TABS DE CONTENIDO EN LISTADO MODAL
.content-background-modal.content-list {
	padding: 0;
	display: flex;
	section.content-boxed.row .col-6 {
		padding: 0;
		margin: 0;
		&:last-child {
			//height: calc(100vh - 400px);
			//height: auto;
			display: flex;
			padding: 80px;
			justify-content: center;
			//background: #fff;
			position: relative;
            z-index: 0;
            flex-direction: column;
            left: -1px;
            p {
                margin-bottom: 12px;
            }
		}
	}
}
ul.pagination-list {
	//height: calc(100vh - 400px);
	//height: auto;
	display: flex;
	flex-direction: column;
	position: relative;
	justify-content: space-between;
	z-index: 1;
	li {
		padding: 24px;
		display: flex;
		height: 100%;
		position: relative;
		padding-left: 65px;
		flex-direction: column;
		&.selected {
			background: #fff;
			border-bottom: 1px solid #e7e7e7;
			border-top: 1px solid #e7e7e7;
			&:before {
				border: 2px solid #2fda90;
				background: #fff;
			}
		}
		&:before {
			content: "";
			width: 12px;
			height: 12px;
			//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
			border-radius: 50%;
			position: absolute;
			border: 2px solid #cfcfcf;
			left: 25px;
			z-index: 2;
			top: 28px;
			background: #f6f9fa;
		}
		&.done {
			&:before {
				border: 2px solid $eggplant80;
				background: $eggplant80;
			}
			&:after {
				background: $eggplant80;
			}
		}
		&:after {
			content: "";
			width: 1px;
			height: 100%;
			position: absolute;
			top: 32px;
			left: 32px;
			display: block;
			background: #cfcfcf;
			z-index: 1;
		}
		&:last-child {
			&:after {
				display: none;
			}
			&.selected {
				border-bottom: 0;
			}
		}
		&:first-child.selected {
			border-top: 0;
		}
	}
}


.avatar-box {
    display: flex;
    border-top: 1px solid #E7E7E7;
    padding-top: 24px;
    margin-top: 24px;
}

.avatar-box img {
    width: 64px;
    height: 64px;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 20px;
}

.content-background-modal.content-list section.content-boxed.row .col-6:last-child .avatar-box .avatar-text p:first-child {
    color: #111;
}

.content-background-modal.content-list section.content-boxed.row .col-6:last-child .avatar-box .avatar-text p {
    margin-bottom: 0;
    color: #707070;
}

p + fun-button button {
    margin-top: 20px;
}

p + fun-file button {
    margin-top: 20px;
}

@media (max-width: 991px) {
    section.content-boxed.row .col-6 {
        border: 0;
    }
    section.content-boxed.row {
        flex-direction: column;
    }
    ul.pagination-list {
        height: auto;
    }
    .content-background-modal.content-list section.content-boxed.row .col-6:last-child {
        display: none;
    }
    section.content-boxed.row .col-6 {
        padding: 0;
    }
    .button-content fun-button button {
        width: 100%;
        margin-top: 20px;
	}
	.relance {
		padding: 24px 20px 0 20px !important;
	}
}
