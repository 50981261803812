@import "../core/colors.scss";
@import "../core/fonts.scss";

.content-background-modal {
    padding: 32px;
    //background: $fun-lightBG;
    border-radius: 4px;
    //height: calc(100vh - 275px);
    height: auto;
    // min-height: 479px;
    overflow-y: auto;
    p.description {
        //@extend %extrasmallInterRegular;
        max-width: 65%;
        margin-bottom: 30px;
    }
}



.card-input.card-input .panel-heading {
    // font-size: 16px;
    // font-weight: bold;
    margin-bottom: 15px;
}

.card-input.card-input .panel-body ul {
    list-style: none;
    display: flex;
    //align-items: center;
    justify-content: space-between;
}

.card-input.card-input .panel-body ul li {
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 100%;
}

.card-input.card-input .panel-body ul li span:first-child {
    // @extend %smallerInterBold;
    // text-transform: uppercase;
    // color: $fun-040;
    margin-bottom: 5px;
}

.card-input.card-input .panel-body ul li span:last-child {
    //@extend %extrasmallInterMedium;
}



ul.list-confirm {
	width: 100%;
    max-width: 620px;
	list-style: none;
    display: block;
    margin: auto;
	li {
		border-bottom: 1px solid #e7e7e7;
		padding: 15px 0;
		span {
			font-size: 14px;
			&:first-child {
				//font-weight: bold;
				//text-transform: uppercase;
				display: inline-block;
				min-width: 20%;
			}
		}
		&:last-child {
            border: 0;
		}
    }

    &.card-type li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        span {
            &:first-child {
                color: #a0a0a0;
                @extend %xxsInterBold;
                // font-size: 11px;
            }
            &:last-child {
                // font-size: 16px;
                @extend %verysmallInterMedium;
            }
        }
        &:last-child {
            border-bottom: 1px solid #e7e7e7;
            margin-bottom: 20px;
        }
    }

}
// .list-confirm li span:last-child {
//     @extend %verysmallInterRegular;
//     color: $fun-100;
// }
.range {
    margin: 0;
    .time-range {
        .title, .bar-range {
            margin: 0;
        }
        .title {
            margin-bottom: 32px;
            p {
                @extend %extrasmallInterRegular;
            }
        }
        .bar-range {
            margin-bottom: 24px;
            label {
                display: inline;
                @extend %xxsInterRegular;
                color: $fun-060;
            }
            .first {
                float: left;
                margin-top: -10px;
            }
            .second {
                float: right;
                margin-top: -10px;
            }
        }
    }
}


.final-step + .container-footer {
    display: none;
}

.final-step {

    h3:before {
        content: "";
        width: 160px;
        height: 160px;
        display: block;
        margin: 0 auto;
        background: url('/assets/images/img/ico-success.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-position: center;
    }
}

.final-step .content-background-modal.row {
    //background: #fff;
    text-align: center;
    padding: 0 20%;
    //justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.final-step > .row {
    // height: 100vh;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.final-step > .row > .container fun-button {
    margin: 15px;
}

.final-step > .row > .container {
    //margin-top: 50px;
}
ul.pagination-tab li {
    @extend %verysmallInterBold;
}
@media (max-width: 991px) {
    .final-step .content-background-modal.row {
        padding: 0 20px;
        font-size: 14px;
        border: 0;
    }

    .final-step > .row > .container {
        display: flex;
        flex-direction: column-reverse;
        width: 100%;
    }

    .final-step > .row > .container fun-button button {
        width: 100%;
    }

    .final-step > .row > .container fun-button {
        margin: 5px 0;
    }
    html body ul.pagination-tab {
        margin-top: 20px;
    }
    html body ul.pagination-tab li {
        display: none;
    }

    html body ul.pagination-tab li.selected {
        display: flex;
        border: 0;
    }

    .content-background-modal p.description {
        width: 100%;
        max-width: 100%;
    }

    ul.list-confirm li {
        display: flex;
        justify-content: space-between;
        padding: 20px 0;
    }

    .container-footer .container {
        justify-content: center;
        width: 100%;
        //
        display: flex;
        flex-direction: column;
    }

    .container-footer .container fun-button:first-child {
        margin-bottom: 8px;
    }

    .container-footer .container fun-button button {
        width: 100%;
    }

    .container-footer .container fun-button {
        width: 100%;
    }

    .content-background-modal {
        //border: 1px solid #e7e7e7;
        padding: 32px 20px;
        height: auto;
    }
    .radiobutton.check-style.form-input {
        flex-direction: column;
        width: 100%;
    }

    .radiobutton.check-style.form-input label .panel {
        margin: 0;
        //padding: 15px;
    }
    .card-input.card-input .panel-body ul li {
        padding: 0 2.5px;
    }

    .card-input.card-input .panel-heading {
        padding-left: 25px;
    }

    .select.form-input {
        width: 100%;
    }
}