/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

@import './assets/sass/core/fonts.scss';
@import './assets/sass/core/colors.scss';
@import './assets/sass/core/transition.scss';

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import './assets/sass/fundeen-ui.scss';

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@font-face {
    font-family: "StratosRegular";
    src: url("./assets/fonts/StratosLCWeb-Regular.woff2");
}

@font-face {
    font-family: "StratosBold";
    src: url("./assets/fonts/StratosLCWeb-Bold.woff2");
}

@font-face {
    font-family: "StratosLight";
    src: url("./assets/fonts/StratosLCWeb-Light.woff2");
}

@font-face {
    font-family: "StratosMedium";
    src: url("./assets/fonts/StratosLCWeb-Medium.woff2");
}

/* width */
::-webkit-scrollbar {
  width: 16px;
  height: 16px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 100vh;
  background: #EAE7E9;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #A07C8D;
  border-radius: 100vh;
  border: 3px solid #EAE7E9;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #F0C9DB;
}

body {
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
}
::selection {
  color: #381B2A;
  background-color: #FFF0B9;
}

img::selection {
  background-color: transparent;
}

.grecaptcha-badge { visibility: hidden; }
.ion-page {
  //overflow-y: auto !important;
}

:root {
  --ion-background-color: transparent;
}
app-not-found {
  background-position: top !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background: url(./assets/images/bg-pages/bg-desktop-tab-1.webp);
}
app-private-layout ion-split-pane {
  background-position: top !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-color: white;
  @media only screen and (min-width: 584px) {
    &.bg-1 {
      background: url(./assets/images/bg-pages/bg-desktop-tab-1.webp);
    }
    &.bg-2 {
      background: url(./assets/images/bg-pages/bg-desktop-tab-2.webp);
    }
    &.bg-3 {
      background: url(./assets/images/bg-pages/bg-desktop-tab-3.webp);
    }
    &.bg-4 {
      background: url(./assets/images/bg-pages/bg-desktop-tab-4.webp);
    }
    &.bg-5 {
      background: url(./assets/images/bg-pages/bg-desktop-tab-5.webp);
    }
    &.bg-6 {
      background: url(./assets/images/bg-pages/bg-ricenergy.webp);
    }
  }
  @media only screen and (max-width: 584px) {
    &.bg-1 {
      background: url(./assets/images/bg-pages/bg-mob-tab-1.webp);
    }
    &.bg-2 {
      background: url(./assets/images/bg-pages/bg-mob-tab-2.webp);
    }
    &.bg-3 {
      background: url(./assets/images/bg-pages/bg-mob-tab-3.webp);
    }
    &.bg-4 {
      background: url(./assets/images/bg-pages/bg-mob-tab-4.webp);
    }
    &.bg-5 {
      background: url(./assets/images/bg-pages/bg-mob-tab-5.webp);
    }
    &.bg-6 {
      background: url(./assets/images/bg-pages/bg-ricenergy.webp);
    }
  }
}

.ion-page {
  //overflow-y: auto !important;
}

.icon-selected {
  color: #E54937;
}
.app-content {
  padding-top: 120px;
}
ion-content {
  --padding-end: 20px;
  --padding-start: 20px;
}
ion-split-pane {
  --side-min-width: 120px !important;
  --side-max-width: 262px !important;
}

// ion-backdrop {
//   display: none;
// }

ion-menu {
  transition: width 1.2s !important;
  &.menu-secondary {
    width: 110px !important;
  }
}
.pop-over-desktop {
  .popover-content {
    position: absolute;
    top: 80px !important;
    right: 16px !important;
  }
}
.swiper-scrollbar {
  width: 50% !important;
  left: 25% !important;
  background-color: rgb(231, 231, 231) !important;
}
.swiper-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
}
.swiper-scrollbar-drag, .swiper-pagination-bullet {
  background-color: rgb(56, 27, 42) !important;
}
.swiper-pagination-bullet-active {
  background-color: #381B2A;
  width: 10px;
  height: 10px;
}
// Estilos para los card de slider
.general-container {
  position: relative;
  .arrow {
    position: absolute;
    display: flex;
    top: calc(50% - 48px);
    z-index: 2;
    border-radius: 50%;
    background: rgba(56, 27, 42, 0.4);
    backdrop-filter: blur(4px);
    box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.06);
    -webkit-box-align: center;
    align-items: center;
    justify-content: center;
    &:active {
      opacity: 0.6;
    }
    &:hover {
      cursor: pointer !important;
    }
  }
  .arrow-left {
    left: 12px;
  }
  .arrow-right {
    right: 12px;
  }
  .arrow-disabled {
    background: rgba(56, 27, 42, 0.1);
    backdrop-filter: blur(4px);
    &:hover {
      cursor: default;
    }
  }
}
// Estilos globales para la cabecera del toolbar
.header-md:after {
  background: none;
  display: none;
}
.header-main {
  border-bottom: 1px solid #e7e7e7;
}

.header-ios ion-toolbar:last-of-type {
  --border-width: 0px 0 -0px;
}
ion-tab-bar {
  background-color: white;
}
ion-button {
  --box-shadow: none;
}
ion-card {
  box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.06);
}
ion-slide:hover {
  cursor: pointer;
}
.inf {
  cursor: pointer;
}
.inf::after {
  content: '';
  display: inline-block;
  background-image: url(./assets/icons/info-small.svg);
  background-size: contain;
  background-repeat: no-repeat;
  width: 10px;
  height: 10px;
}
.appear {
  @extend %appear;
}
select {
  @extend %extrasmallInterRegular;
}

// Estilos para autocomplete
.pac-container {
  z-index: 3000 !important;
}

// Estilos para el video de youtube
.video {
  height: 100%;
  position: relative;
  width: calc(100% - 10px);
  overflow: hidden;
  .overlay {
      top: 0;
      left: 0;
      z-index: 8;
      position: absolute;
      width: 100%;
      height: calc(100% - 5px);
      /* pointer-events: none; */
  }
}

// Estilos para los degradados

.linear-gradient-1 { background: linear-gradient(54.81deg, #FF804D 17.58%, #FF8C92 47.6%, #FFCD3E 77.62%) }
.linear-gradient-2 { background: linear-gradient(45deg, #FF7C92 0%, #FF5F3E 48.7%, #FF68C5 100%) }
.linear-gradient-3 { background: linear-gradient(55.74deg, #FFF1BA 9.95%, #FFCD3E 87.43%) }
.linear-gradient-4 { background: linear-gradient(45deg, #FF5555 0%, #9EA7F7 100%) }
.linear-gradient-5 { background: linear-gradient(55.74deg, #FF6C90 9.95%, #FFCD3E 87.43%) }
.linear-gradient-6 { background: linear-gradient(84.03deg, #FFB360 3.06%, #FFF0B9 74.14%) }
.linear-gradient-7 { background: linear-gradient(45deg, #FFFC99 0%, #FFB7E7 48.7%, #F8B099 100%) }

.bg-ric {
  background: #0f5e42;
}

.bg-gradient-1-text {
  background: linear-gradient(55.74deg, #FF804D 9.95%, #FF8C92 48.69%, #FFCD3E 87.43%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.bg-gradient-2-text {
  background: linear-gradient(45deg, #FFD43C 0%, #FF679B 53.12%, #FF5C2F 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.bg-gradient-3-text {
  background: linear-gradient(45deg, #FF7C92 0%, #FF5F3E 48.7%, #FF68C5 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.bg-gradient-1-text::selection, .bg-gradient-2-text::selection, .bg-gradient-3-text::selection {
  -webkit-background-clip: initial;
  -webkit-text-fill-color: #381B2A;
  background-clip: initial;
  text-fill-color: #381B2A;
}

@media (max-aspect-ratio: 16/9) {
  .video iframe {
      /* width = 100 / (9 / 16) = 177.777777 */
      width: 177.78vh;
  }
}

@media only screen and (max-width: 774px) {
  .general-container {
    .arrow {
      top: calc(50% - 17px) !important;
    }
  }
}

@media only screen and (min-width: 774px) {
  ion-content {
    --padding-end: 40px;
    --padding-start: 40px;
    width: 100%;
    .general-container {
      height: auto;
      margin: auto;
      width: 100%;
      max-width: 1200px;
    }
  }
}

@media only screen and (min-width: 995px) {
  ion-tab-bar {
    display: none !important;
  }
  .modal-wrapper {
    width: calc(100% - 228px) !important;
    height: 100% !important;
    --box-shadow: none !important;
  }
  app-public-layout, app-register {
    background: url(./assets/images/new_branding/bg-login-desktop.webp);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
}
@media only screen and (max-width: 994px) {
  app-public-layout, app-register {
    background: url(./assets/images/new_branding/bg-login.webp);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .home-content {
    background: none !important;
  }
  html,
  body,
  .ion-app,
  .ion-content,
  ion-app,
  ion-content {
    background-color: transparent !important;
  }
}

*:focus {
  outline: none !important;
}
/* .mob-general {
  .header h2 {
      padding-top: 12px !important;
  }
} */
.register {
  /* max-height: calc(100% - 94px); */
  //overflow: auto;
  .span-container {
    height: 1px !important;
    min-height: 1px !important;
    @media screen and (max-width: 991px) {
      display: none !important;
    }
    span {
      display: none !important;
    }
  }
  .select.form-input,
  select,
  option {
    width: 100%;
  }
  // label {
  //   color: $fun-060;
  //   line-height: 21px;
  // }
  select:hover {
    cursor: pointer;
  }
  .investor-steps {
    li {
      padding-left: 44px;
      position: relative;
      margin-bottom: 30px;
      h3 {
        margin-bottom: 8px;
        @extend %extrasmallInterBold;
      }
      p {
        //@extend %extrasmallInterRegular;
      }
      h3::before {
        counter-increment: my-sec-counter;
        content: counter(my-sec-counter);
        background-color: $fun-blue;
        color: $fun-white;
        width: 30px;
        height: 26px;
        text-align: center;
        padding-top: 4px;
        border-radius: 50%;
        position: absolute;
        left: 0px;
        top: -4px;
        z-index: 2;
      }
    }
  }
  .checkbox {
    label {
      @extend %extrasmallInterRegular;
      color: $fun-060;
    }
  }
  .checkbox:last-child {
    margin-bottom: 0 !important;
  }
  .document-uploaded {
    position: relative;
    padding: 18px 0;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    width: 100%;
    .file {
      margin-right: 15px;
    }
    h5 {
      margin: 0;
      @extend %extrasmallInterMedium;
    }
    .grey {
      color: $fun-020;
    }
    .download {
      position: absolute;
      right: 0px;
    }
    .download:hover,
    .file:hover,
    p:hover {
      cursor: pointer;
    }
  }
  .body-content {
    .phone {
      .phone-c {
        max-width: 520px;
        display: block;
        margin: auto;
        margin-bottom: 24px;
      }
      article {
        margin-bottom: 14px;
      }
    }
    .step {
      .header {
        p {
          padding-bottom: 40px;
        }
      }
      .body {
        max-width: 520px;
        display: block;
        margin: auto;
        .inp {
          margin-bottom: 16px;
        }
      }
      h2 {
        text-align: center;
        background: linear-gradient(45deg, #FFD43C 0%, #FF679B 53.12%, #FF5C2F 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
      }
      // p {
      //   @extend %verysmallInterRegular;
      //   color: $fun-060;
      //   span {
      //     font-weight: bold;
      //   }
      // }
      .body-info {
        @extend %extrasmallInterRegular;
        color: $fun-040;
      }
      article {
        fun-checkbox {
          float: left;
          margin-right: 12px;
        }
        h3 {
          padding-top: 1px;
          float: left;
          margin: 0;
          @extend %xxsInterRegular;
          color: $fun-060;
          span {
            color: $fun-mainGreen;
          }
        }
        div {
          clear: both;
        }
      }
      .code-container {
        position: relative;
        p {
          margin-top: 40px;
        }
      }
      .name-container {
        fun-input {
          margin-bottom: 16px;
        }
      }
    }
  }
  .desktop-footer {
    align-self: flex-end;
    height: 120px;
    background-color: #ffffff;
    //position: absolute;
    bottom: 46px;
    left: 0;
    padding: 24px 94px;
    width: 100%;
    border-top: 1px solid $fun-010;
    .content-footer {
      padding-bottom: 52px;
      position: relative;
      display: flex;
      justify-content: center;
      .back-btn {
        z-index: 1;
        position: absolute;
        left: 0;
      }
      .next-btn {
        z-index: 1;
        position: absolute;
        right: 0;
      }
      h4 {
        z-index: 444;
        padding-top: 18px;
        margin: 0;
        position: relative;
        letter-spacing: 1px;
        text-transform: uppercase;
        text-align: center;
        // color: $fun-mainGreen;
        // @extend %xxsInterBold;
      }
      // h4:hover {
      //   cursor: pointer;
      // }
    }
  }
  .mobile-footer {
    h4 {
      z-index: 444;
      margin: 24px 0 24px 0;
      letter-spacing: 1px;
      text-transform: uppercase;
      text-align: center;
      // color: $fun-mainGreen;
      // @extend %xxsInterBold;
    }
  }
  // h4:hover {
  //   cursor: pointer;
  // }
  :host::ng-deep fun-button button {
    padding: 16px 32px !important;
  }
  @media screen and (min-width: 991px) {
    // Pantallas grandes
    height: 100%;
    //max-height: calc(100% - 94px);
    .body-content {
      //width: 384px;
      max-width: 920px;
      display: block;
      margin: auto;
      margin-top: 62px;
    }
    .mobile-footer {
      display: none;
    }
  }
  @media screen and (min-width: 991px) {
    //height: calc(100% - 94px);
    .body {
      padding-bottom: 40px;
      border-radius: 8px 8px 0px 0px;
    }
  }
  @media screen and (max-width: 991px) {
    .header {
      padding: 0px 20px 32px 20px;
      p {
        padding: 0 !important;
      }
      //background-color: $fun-lightBG;
    }
    .mobApp {
      h2 {
        margin: 0;
      }
      //background: #f6f9fa;
      padding-left: 20px;
      padding-right: 20px;
    }
    .body {
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 40px;
      padding-top: 12px;
      background: #ffffff;
      border-radius: 8px 8px 0px 0px;
    }
    .mobile-footer {
      position: relative;
      /* left: 14px;
          bottom: 24px;
          width: 100%;
          padding-right: 28px;
          z-index: 2; */
    }
    .desktop-footer {
      display: none;
    }
    :host::ng-deep fun-button button {
      width: 100% !important;
      margin-top: 10px;
    }
  }
  @media screen and (max-height: 800px) and (min-height: 710px) {
    .scrollable {
      height: 392px;
      overflow: auto;
    }
  }
  @media screen and (max-height: 710px) and (min-height: 638px) {
    .scrollable {
      height: 300px;
      overflow: auto;
    }
  }
  @media screen and (max-height: 638px) {
    .scrollable {
      height: 275px;
      overflow: auto;
    }
  }
  @media screen and (max-height: 850px) and (min-width: 750px) {
    .mob-general {
      height: 500px;
    }
    .scrollable {
      height: 450px !important;
      overflow: auto;
    }
  }
  @media only screen and (min-width: 992px) {
    .mob-general {
      padding-bottom: 40px;
    }
  }
}

@media only screen and (max-width: 994px) {
  div#hubspot-messages-iframe-container {bottom: 80px !important;max-height: calc(100% - 80px) !important;}
}