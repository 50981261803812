.ico {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 5px;
    background-size: contain;
    background-repeat: no-repeat;
    vertical-align: middle;
    background-position: center;
    &.ico-edit {
        // background-image: url("/assets/images/img/ico-more.svg");
        margin-right: 20px;
    }
    &.ico-fgreen {
        background-image: url("/assets/images/img/ico-intern-project.svg");
    }
    &.ico-info {
        background-image: url("/assets/images/img/ico-info.svg");
    }
    &.ico-info-green {
        background-image: url("/assets/images/img/ico-info-green.svg");
    }
    &.ico-info-orange {
        background-image: url("/assets/images/img/ico-info-orange.svg");
    }
    &.ico-back {
        background-image: url("/assets/images/img/ico-arrow-left.svg");
    }
    &.ico-arrow {
        background-image: url("/assets/images/img/ico-arrow-down.svg");
    }
    &.ico-arrow-right {
        background-image: url("/assets/images/img/ico-arrow-down.svg");
        transform: rotate(270deg);
    }
    &.ico-arrow-right-green {
        background-image: url("/assets/images/img/ico-arrow-left.svg");
        transform: rotate(180deg);
    }
    &.ico-arrow-right-green:hover {
        cursor: pointer;
    }
}