
.card-container {
    width: 100%;
    height: auto;
    position: relative;
    //max-width: 435px;
    &.card-detail {
        max-width: none;
        .subheader-container .content {
            margin-left: 32px;
            padding-top: 32px;
            .selected {
                border-bottom: 4px solid $fun-blue;
            }
        }

        .info-container {
            position: absolute;
            top: 86px;
            background: #fff;
            //width: 443px;
            padding: 20px;
            right: 12px;
            border-radius: 4px;
            box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.06);
        }
        .large {
            width: 443px;
        }
        .short {
            width: 307px;
            .time-remaining {
                width: 60% !important;
            }
            .global-income {
                width: 40% !important;
            }
        }

        .info-container .info-subheader {
            display: flex;
            justify-content: space-between;
        }
        .bg-image,
        .filter,
        .header {
            height: 203px;
        }
    }
    .bg-image {
        position: absolute;
        width: 100%;
        height: 140px;
        border-radius: 4px;
    }
    .filter {
        position: absolute;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.43) 0%, rgba(0, 0, 0, 0.88) 50.05%, #000000 100%);
        opacity: 0.4;
        width: 100%;
        height: 140px;
        border-radius: 4px;
    }
    .header {
        width: 100%;
        height: 140px;
        top: 0;
        opacity: 0.4;
        opacity: 0.9;
        color: $fun-white;
        border-radius: 4px;
        .title-container {
            padding-top: 64px;
            margin-left: 20px;
            h2 {
                margin: 0 auto;
                @extend %mediumInterBold;
            }
            p {
                margin: 0 auto;
                margin-top: 4px;
                @extend %xxsInterRegular;
            }
        }
    }
    .info-container {
        margin-left: 20px;
        margin-right: 20px;
        .info-legend{
            @extend %smallerInterBold;
            color: $fun-040;
            text-transform: uppercase;
            margin: 0;
        }
        .info-sub {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            padding-top: 4px;
            @extend %smallInterBold;
            .no-resalt {
                @extend %verysmallInterBold;
            }
            margin: 0;
        }
        .global-income, .time-remaining, .pr-to-cap {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: inline-block;
        }
        .global-income {
            width: 40%; //84px;
        }
        .time-remaining {
            width: 50%; //161px;
        }
        .pr-to-cap {
            //width: 30%; //86px;
        }
        .line {
            .mar-top {
                @extend %spacingTwo;
            }
            .grey-line {
                width: 100%;
                border: .5px solid $fun-010;
            }
        }
    }
    .content-container {
        .tag {
            margin-left: 20px;
            margin-top: -12px;
            position: absolute;
        }
        .spacing-four {
            display: inline-block;
            @extend %spacingFour;
        }
        .spacing-three {
            @extend %spacingThree;
        }
        .offers-container {
            margin-right: 20px;
            margin-left: 20px;
            h2 {
                margin: 0;
                margin-bottom: 4px;
                text-transform: uppercase;
                display: inline-block;
                @extend %xxsInterBold;
            }
            p {
                margin: 0;
                display: inline-block;
                @extend %extrasmallInterRegular;
            }
            .offers-header, .offers-content {
                * {
                    vertical-align: middle;
                }
                .seller, .sellerC {
                    width: 25%;
                    /* margin-right: 5%; */
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
                .share, .shareC {
                    width: 20%;
                    /* margin-right: 2%; */
                }
                .em-value, .em-valueC {
                    width: 25%;
                    /* margin-right: 2%; */
                }
                .bid, .bidC {
                    width: 10%;
                }
                .buttonC {
                    width: 20%;
                    text-align: center;
                }
            }
            .offers-content {
                p {
                    padding-top: 10px;
                    padding-bottom: 10px;
                }
            }
            .offers-content-line {
                width: 100%;
                border: 1px solid $fun-005;
            }

        }
        .button-container {
            width: 100%;
            text-align: center;
            padding: 20px;
            p{
                padding: 17px;
                margin-left: 20px;
                margin-right: 20px;
                border: 1px solid $fun-020;
                border-radius: 4px;
                letter-spacing: 1px;
                text-transform: uppercase;
                color: $fun-060;
                @extend %xxsInterBold;
            }
        }
        .button-container:hover {
            cursor: pointer;
        }
    }
}
:host ::ng-deep .button button {
    margin-top: 15px;
    width: 100%;
}

@media only screen and (max-width: 601px) {
    .card-container {
        width: 100%;
        height: auto;
        .bg-image, .filter, .header {
            width: 100%;
            height: 120px;
        }
    .title-container {
        padding-top: 50px !important;
    }
    .spacing-four {
        height: 36px !important;
    }
    .info-sub {
        font-size: 16px !important;
        line-height: 22px !important;
    }
    .second {
        display: none !important;
    }
    .pr-to-cap {
        margin-top: 16px;
    }
    .seller{
        width: 77px;
        margin-right: 15px !important;
    }
    .share {
        width: 67px !important;
        margin-right: 27px !important;
    }
    .em-value {
        width: 50px !important;
        margin-right: 24px !important;
    }
    }
    :host ::ng-deep .button button {
        width: 287px;
    }
}

@media (max-width: 992px) {
    .card-container.card-detail .info-container {
        position: static;
        width: 100%;
        margin: 0;
        align-items: flex-start;
        padding: 30px 10px;
        justify-content: start;
    }

    .card-container .info-container .pr-to-cap {
        margin-top: 0;
    }
    .info-container  {
        .info-subheader {
            .global-income {
                //width: 33% !important;
                padding-right: 5px;
            }
            .time-remaining {
                //width: 42% !important;
                padding-right: 5px;
            }
            .pr-to-cap {
                //width: 25% !important;
            }
        }
    }

    .card-container .info-container .info-legend {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
    }
    .card-container.card-detail .subheader-container .content {
        margin-left: 15px;
        white-space: nowrap;
        overflow: auto;
        padding-right: 28px;
    }

    /*.card-container.card-detail .subheader-container:after {
      content: "";
      height: 100%;
      width: 100px;
      background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
      display: block;
      position: absolute;
      right: 0px;
      top: 0;
    }*/

    .card-container.card-detail .subheader-container {
        position: relative;
    }
}

.final-step.offer,
.final-step.bid {
    //border: 1px solid #E0E5E7;
    border-radius: 4px;
    margin: 24px 0;
    padding: 50px 20px;
    height: auto;
    h3:before {
        content: "";
        width: 160px;
        height: 160px;
        display: block;
        margin: 0 auto;
        background: url('/assets/images/illustrations/notifications.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
    p {
        font-size: 14px;
        text-align: center;
        color: #414141;
        margin: 20px;
        padding: 0 25%;
        @media (max-width: 992px) {
            padding: 0 20px;
        }
    }

    .container {
        text-align: center;
    }
}

.final-step.bid {
    h3:before {
        content: "";
        width: 160px;
        height: 160px;
        display: block;
        margin: 0 auto;
        background: url('/assets/images/illustrations/notifications.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
}
@media only screen and (min-width: 1042px) {
    .table-container {
        //padding-right: 48px;
    }
}
@media only screen and (min-width: 798px) and (max-width: 1041px){
    .table-container {
        padding-right: 18px;
    }
}

