@import './colors.scss';
@import './fonts.scss';

html,
body,
* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

html body {
  background-color: $fun-lightBG;
}

ul {
  list-style: none;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.center {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.justify {
  text-align: justify;
}

.hidden-sm {
  display: none !important;
}

// grid

$width: 100%;
$gutter: 0%;
$breakpoint-small: 480px; // 540px
$breakpoint-med: 798px; // 720px
$breakpoint-large: 1024px; // 960px

.container {
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  margin: 0 auto;
  @media only screen and (min-width: $breakpoint-small) {
    width: 100%;
    padding: 0;
  }

  @media only screen and (min-width: $breakpoint-large) {
    width: 100%;
    max-width: 1440px;
  }
}

.row {
  position: relative;
  width: 100%;
  &.w-padding,
  .w-padding {
    padding: 0 12px;
    @media (max-width: 767px) {
      padding: 0;
    }
  }
  &.h-padding,
  .h-padding {
    padding: 12px 0;
    @media (max-width: 767px) {
      padding: 0 0 20px;
    }
  }
}

.container .container {
  padding: 0;
}

.row [class^='col'] {
  float: left;
  margin: 15px 0;
  min-height: 0.125rem;
}

.row::after {
  content: '';
  display: table;
  clear: both;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12 {
  width: $width;
}

.col-1-sm {
  width: ($width / 12) - ($gutter * 11 / 12);
}
.col-2-sm {
  width: ($width / 6) - ($gutter * 10 / 12);
}
.col-3-sm {
  width: ($width / 4) - ($gutter * 9 / 12);
}
.col-4-sm {
  width: ($width / 3) - ($gutter * 8 / 12);
}
.col-5-sm {
  width: ($width / (12 / 5)) - ($gutter * 7 / 12);
}
.col-6-sm {
  width: ($width / 2) - ($gutter * 6 / 12);
}
.col-7-sm {
  width: ($width / (12 / 7)) - ($gutter * 5 / 12);
}
.col-8-sm {
  width: ($width / (12 / 8)) - ($gutter * 4 / 12);
}
.col-9-sm {
  width: ($width / (12 / 9)) - ($gutter * 3 / 12);
}
.col-10-sm {
  width: ($width / (12 / 10)) - ($gutter * 2 / 12);
}
.col-11-sm {
  width: ($width / (12 / 11)) - ($gutter * 1 / 12);
}
.col-12-sm {
  width: $width;
}

@media only screen and (min-width: $breakpoint-med) {
  .col-1 {
    width: ($width / 12) - ($gutter * 11 / 12);
  }
  .col-2 {
    width: ($width / 6) - ($gutter * 10 / 12);
  }
  .col-3 {
    width: ($width / 4) - ($gutter * 9 / 12);
  }
  .col-4 {
    width: ($width / 3) - ($gutter * 8 / 12);
  }
  .col-5 {
    width: ($width / (12 / 5)) - ($gutter * 7 / 12);
  }
  .col-6 {
    width: ($width / 2) - ($gutter * 6 / 12);
  }
  .col-7 {
    width: ($width / (12 / 7)) - ($gutter * 5 / 12);
  }
  .col-8 {
    width: ($width / (12 / 8)) - ($gutter * 4 / 12);
  }
  .col-9 {
    width: ($width / (12 / 9)) - ($gutter * 3 / 12);
  }
  .col-10 {
    width: ($width / (12 / 10)) - ($gutter * 2 / 12);
  }
  .col-11 {
    width: ($width / (12 / 11)) - ($gutter * 1 / 12);
  }
  .col-12 {
    width: $width;
  }

  .hidden-sm {
    display: inline-block !important;
  }
}

@media only screen and (min-width: $breakpoint-large) {
  .hidden-lg {
    display: none !important;
  }
}

@media only screen and (max-width: 992px) {
  .hidden-sm {
    display: none !important;
  }
  .back-project {
    margin-top: 0 !important;
  }
}

.back-project {
  p,
  i {
    display: inline-block;
  }
  i {
    margin-bottom: 3px;
  }
  p {
    @extend %verysmallInterMedium;
    color: $fun-100;
  }
  margin: 15px 0;
  cursor: pointer;
  .ico {
    margin-right: 10px;
  }
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
  &:active {
    opacity: 0.6;
  }
}

fun-offer .container {
  padding-left: 0px;
  padding-right: 0px;
}
