
.subheader-container {
    margin: 0;
    .content {
        margin-left: 120px;
        padding-top: 18px;
        p {
            display: inline-block;
            margin-right: 40px;
            padding-bottom: 18px;
            @extend %extrasmallInterMedium;
        }
        p:hover {
            cursor: pointer;
        }
        .selected {
            color: $fun-100;
            border-bottom: 4px solid $fun-mainGreen;
        }
        .default {
            color: $fun-040
        }
    }
}
@media only screen and (max-width: 600px) {
    .subheader-container {
        .content {
            margin-left: 25px;
            p {
                margin-right: 32px;
                padding-bottom: 12px;
            }
            .selected {
                border-bottom: 2px solid $fun-mainGreen;
            }
        }
    }
}
