@import './colors.scss';

@mixin font-face($font-family, $font-name) {
  @font-face {
    font: {
      family: $font-family;
      font-display: block; //fallback;
    }
    src: url('/assets/fonts/' + $font-name + '.woff2') format('woff2');
  }
}

@include font-face(StratosLCWeb-Bold, StratosLCWeb-Bold);
@include font-face(StratosLCWeb-Medium, StratosLCWeb-Medium);
@include font-face(StratosLCWeb-Regular, StratosLCWeb-Regular);

%link {
  font-family: StratosLCWeb-Bold;
  color: $fun-060;
  &:active {
    color: $fun-mainGreen;
  }
  &:hover {
    cursor: Pointer;
  }
}
%linkDisabled {
  font-family: StratosLCWeb-Bold;
  color: $fun-020;
}

%bigInterBold {
  font-family: StratosLCWeb-Bold;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 36px;
  /* Style for firefox */
  @-moz-document url-prefix() {
    font-weight: 500 !important;
  }
  /* Style for Safari */
  _::-webkit-full-page-media,
  _:future,
  :root .safari_only {
    font-weight: 500 !important;
  }
  @media not all and (min-resolution: 0.001dpcm) {
    font-weight: 500 !important;
  }
}
%bigInterMedium {
  font-family: StratosLCWeb-Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 36px;
}
%bigInterRegular {
  font-family: StratosLCWeb-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 36px;
}

%mediumInterBold {
  font-family: StratosLCWeb-Bold;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  /* Style for firefox */
  @-moz-document url-prefix() {
    font-weight: 500 !important;
  }
  /* Style for Safari */
  _::-webkit-full-page-media,
  _:future,
  :root .safari_only {
    font-weight: 500 !important;
  }
  @media not all and (min-resolution: 0.001dpcm) {
    font-weight: 500 !important;
  }
}
%mediumInterMedium {
  font-family: StratosLCWeb-Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
}
%mediumInterRegular {
  font-family: StratosLCWeb-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;
}

%smallInterBold {
  font-family: StratosLCWeb-Bold;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 26px;
  /* Style for firefox */
  @-moz-document url-prefix() {
    font-weight: 500 !important;
  }
  /* Style for Safari */
  _::-webkit-full-page-media,
  _:future,
  :root .safari_only {
    font-weight: 500 !important;
  }
  @media not all and (min-resolution: 0.001dpcm) {
    font-weight: 500 !important;
  }
}
%smallInterMedium {
  font-family: StratosLCWeb-Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
}
%smallInterRegular {
  font-family: StratosLCWeb-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 26px;
}

%verysmallInterBold {
  font-family: StratosLCWeb-Bold;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  /* Style for firefox */
  @-moz-document url-prefix() {
    font-weight: 500 !important;
  }
  /* Style for Safari */
  _::-webkit-full-page-media,
  _:future,
  :root .safari_only {
    font-weight: 500 !important;
  }
  @media not all and (min-resolution: 0.001dpcm) {
    font-weight: 500 !important;
  }
}
%verysmallInterMedium {
  font-family: StratosLCWeb-Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
}
%verysmallInterRegular {
  font-family: StratosLCWeb-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
}

%extrasmallInterBold {
  font-family: StratosLCWeb-Bold;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  /* Style for firefox */
  @-moz-document url-prefix() {
    font-weight: 500 !important;
  }
  /* Style for Safari */
  _::-webkit-full-page-media,
  _:future,
  :root .safari_only {
    font-weight: 500 !important;
  }
  @media not all and (min-resolution: 0.001dpcm) {
    font-weight: 500 !important;
  }
}
%extrasmallInterMedium {
  font-family: StratosLCWeb-Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}
%extrasmallInterRegular {
  font-family: StratosLCWeb-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
}

%xxsInterBold {
  font-family: StratosLCWeb-Bold;
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  line-height: 16px;
  /* Style for firefox */
  @-moz-document url-prefix() {
    font-weight: 500 !important;
  }
  /* Style for Safari */
  _::-webkit-full-page-media,
  _:future,
  :root .safari_only {
    font-weight: 500 !important;
  }
  @media not all and (min-resolution: 0.001dpcm) {
    font-weight: 500 !important;
  }
}
%xxsInterMedium {
  font-family: StratosLCWeb-Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
}
%xxsInterRegular {
  font-family: StratosLCWeb-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 16px;
}

%smallerInterBold {
  font-family: StratosLCWeb-Bold;
  font-style: normal;
  font-weight: bold;
  font-size: 9px;
  line-height: 12px;
  /* Style for firefox */
  @-moz-document url-prefix() {
    font-weight: 500 !important;
  }
  /* Style for Safari */
  _::-webkit-full-page-media,
  _:future,
  :root .safari_only {
    font-weight: 500 !important;
  }
  @media not all and (min-resolution: 0.001dpcm) {
    font-weight: 500 !important;
  }
}
%smallerInterMedium {
  font-family: StratosLCWeb-Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 9px;
  line-height: 12px;
}
%smallerInterRegular {
  font-family: StratosLCWeb-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 9px;
  line-height: 12px;
}
