%spacingOne {
  width: 8px;
  height: 8px;
}
%spacingTwo {
  width: 16px;
  height: 16px;
}
%spacingThree {
  width: 24px;
  height: 24px;
}
%spacingFour {
  width: 32px;
  height: 32px;
}
%spacingFive {
  width: 40px;
  height: 40px;
}
%spacingSix {
  width: 48px;
  height: 48px;
}
%spacingSeven {
  width: 56px;
  height: 56px;
}
%spacingEight {
  width: 64px;
  height: 64px;
}
%spacingNine {
  width: 72px;
  height: 72px;
}
%spacingTen {
  width: 80px;
  height: 80px;
}
%spacingEleven {
  width: 88px;
  height: 88px;
}
%spacingTwelve {
  width: 96px;
  height: 96px;
}
