.container-footer {
    border-top: 1px solid #E7E7E7;
    margin-top: 30px;
    padding-top: 30px;
    margin-bottom: 30px;
    .container {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.space-bottom {
    margin-bottom: 10px;
}