@import "../core/fonts.scss";

.tables {
    margin-bottom: 40px;
    h2 {
        margin: 0;
        margin-bottom: 4px;
        text-transform: uppercase;
        display: inline-block;
        @extend %xxsInterBold;
    }
    p {
        margin: 0;
        display: inline-block;
        @extend %extrasmallInterRegular;
       &.action {
            text-align: right;
            a.a-link {
                text-transform: uppercase;
                font-weight: 700;
                text-decoration: none;
                font-size: 11px;
                display: inline-block;
                letter-spacing: 1px;
                color: $fun-mainGreen;
            }
            i.ico.ico-info-green,
            i.ico.ico-info-orange {
              margin-right: 15px;
            }
        }
    }
    .offers-header {
      //color: $fun-040;
      margin-top: 25px;
      padding-bottom: 15px;
      border-bottom: 1px solid $fun-010;
  }
    .offers-header, .offers-content {
        * {
            vertical-align: middle;
        }
        .seller {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
    .offers-content {
        border-bottom: 1px solid $fun-010;
        position: relative;
        p {
            padding-top: 10px;
            padding-bottom: 10px;
        }
        a.ico.ico-edit {
          opacity: 0;
          transition: 0.2s all ease-in-out;
        }
        &:hover {
          background-color: #fff;
          a.ico.ico-edit {
            display: inline-block;
            opacity: 1;
          }
        }
    }
    .offers-content-line {
        width: 100%;
        border: 1px solid $fun-005;
    }
    .offers-header h2, .offers-content p {
        width: 13%;
        margin: 0 0.5%;
        &:nth-child(1) {
            width: 23%;
            padding-left: 10px;
          }

          &:nth-child(2) {
            width: 8%;
            text-align: right;
          }

          &:nth-child(3) {
            width: 12%;
            text-align: right;
          }

          &:nth-child(4) {
            width: 8%;
            margin-right: 2%;
            text-align: right;
          }

          &:nth-child(5){
            width: 15%;
          }

          &:nth-child(6) {
            width: 15%;
          }

          &:nth-child(7) {
            width: 10%;
            text-align: right;
          }
      }

      .offers-content p {
        &.show-more.hidden-lg {
          display: none;
        }
      }

      %profile {
        width: 34px;
        height: 34px;
        display: inline-flex;
        vertical-align: middle;
        font-size: 14px;
        justify-content: center;
        align-items: center;
        //background: $fun-mainGreen;
        border-radius: 50%;
        color: #fff;
        margin-right: 10px;
    }
    .green {
      @extend %profile;
      background: linear-gradient(54.81deg, #FF804D 17.58%, #FF8C92 47.6%, #FFCD3E 77.62%);
    }
    .blue {
      @extend %profile;
      background: linear-gradient(45deg, #FF5555 0%, #9EA7F7 100%);
    }
    .red {
      @extend %profile;
      background: linear-gradient(84.03deg, #FFB360 3.06%, #FFF0B9 74.14%);
    }
    .brown {
      @extend %profile;
      background: linear-gradient(45deg, #FFFC99 0%, #FFB7E7 48.7%, #F8B099 100%);
    }

    @media (max-width: 992px) {
      .offers-header {
        display: none;
      }
      .offers-header h2:nth-child(1),
      .offers-content p:nth-child(1) {
          width: 50%;
      }

      .offers-header h2:nth-child(2),
      .offers-content p:nth-child(2) {
          width: calc(50% - 22px)!important;
          padding-right: 50px;
      }

      .offers-content p:nth-child(3),
      .offers-content p:nth-child(4),
      .offers-content p:nth-child(5),
      .offers-content p:nth-child(6),
      .offers-content p:nth-child(7),
      .offers-content p:nth-child(8) {
          display: none;
          border-bottom: 1px solid #F3F3F3;
      }


      .offers-content.open p:nth-child(3),
      .offers-content.open p:nth-child(4),
      .offers-content.open p:nth-child(5),
      .offers-content.open p:nth-child(6),
      .offers-content.open p:nth-child(7) {
          width: 100%;
          text-align: right;
          position: relative;
          display: block;
          padding: 10px;
      }

      .offers-content p span.hidden-lg {
          float: left;
          text-transform: uppercase;
          // font-size: 11px;
          // font-family: Inter-Bold;
          // font-weight: bold;
      }

      .offers-content .action a.ico.ico-edit {
          display: none;
      }

      p.action a.a-link {
          width: 100%;
          text-align: center;
          padding: 15px;
          background: $eggplant100;
          color: $cream100;
          border-radius: 4px;
      }

      .offers-content:hover {
          background: transparent;
      }

      .offers-content p.show-more.hidden-lg {
          display: inline-block;
          width: 20px;
          position: absolute;
          top: 5px;
          right: 10px;
          border: 0;
      }
      .tables .offers-content.open i.ico.ico-arrow {
        transform: rotate(180deg);
      }

      .offers-content .ico.ico-arrow {
        width: 12px;
        height: 7px;
        margin-right: 0px;
      }
    }

    &.my-offer {
      .offers-header h2, .offers-content p {
        width: 44%;
        margin: 0 0.5%;
          &:nth-child(1) {
            width: 23%;
            padding-left: 10px;
          }

          &:nth-child(3) {
            width: 13%;
            text-align: right;
          }

          &:nth-child(4) {
            width: 14%;
            text-align: right;
          }

          &:nth-child(5) {
            width: 13%;
            margin-right: 2%;
            text-align: right;
          }

          &:nth-child(6){
            width: 30%;
          }
      }
      a.ico.ico-edit {
        margin-left: 24px;
      }
      .offers-header h2.sort {
          text-align: right;
          //font-weight: normal!important;
          //font-family: Inter-Regular;
      }


      .offers-header h2 {
          color: #111;
      }

      .offers-header h2.sort span {
          font-weight: bold;
          padding-left: 4px;
      }

      .offers-header h2.sort span i.ico.ico-arrow-right {
          width: 12px;
          margin-left: 15px;
      }
      @media (max-width: 991px) {
        .offers-content p:nth-child(1) {
          width: 50%;
        }
        .offers-content.open p:nth-child(3),
        .offers-content.open p:nth-child(4),
        .offers-content.open p:nth-child(5),
        .offers-content.open p:nth-child(6) {
          width: 100%;
          text-align: right;
          position: relative;
          display: block;
          padding: 10px;
        }
        .offers-content.open p.action label.label-tag {
          display: none!important;
        }
        .offers-content.open p.action i.ico {
          display: none!important;
        }
      }

  }
}



label.label-tag {
  padding: 10px;
  border-radius: 4px;
  // background-color: rgba(#FEA100, 0.15);
  // color: #FEA100;
  text-transform: uppercase;
  font-size: 9px;
  width: 100px;
  text-align: center;
  letter-spacing: 1px;
  font-weight: bolder;
  display: inline-block;

  &-orange {
    background-color: rgba(#FEA100, 0.15);
    color: #FEA100;
  }
  &-green {
    color: #1CB609;
    background-color: rgba(#1CB609, 0.15);
  }
  &-red {
    color: #FF2F23;
    background-color: rgba(#FF2F23, 0.15);
  }
  @media (max-width: 992px) {
    padding: 9px 12px 7px 12px;
    width: 84px;
    letter-spacing: 0.3px;
  }
}


ul.filter-tab {
  width: 100%;
  display: block;
  list-style: none;
  white-space: nowrap;
  padding: 0;
  margin: 0;
  overflow-y: auto;
  padding-bottom: 10px;
  //padding-top: 10px;

  li {
    padding: 10px 16px;
    border-radius: 4px;
    border: 1px solid #CFCFCF;
    background: #F9FAFA;
    display: inline-block;
    @extend %extrasmallInterMedium;
    margin-right: 8px;
    cursor: pointer;
    &.active {
      background: #2E7EFF;
      border-color: #2e7eff;
      color: #fff;
    }
    &:hover {
      opacity: 0.8;
      box-shadow: 0 0 10px rgba(0,0,0,0.04);
    }
  }
}

.tables.offers-container {
  margin-top: 24px;
}

.text-green {
  color: #1CB609;
}

.text-orange {
  color: #FEA100;
}
.text-red {
  color: #FF2F23;
}

