%rotationDown {
  transform: rotateX(0deg);
  -ms-transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  transition: all 0.5s;
}

%rotationUp {
  transform: rotateX(180deg);
  -ms-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transition: all 0.5s;
}

%appear {
  animation: appear 1s ease-in-out forwards;
}

%disappear {
  animation: disappear 1s ease-in-out forwards;
}

@keyframes disappear {
  0% {
    //height: auto;
    opacity: 1;
    overflow: hidden;
  }
  50% {
    //height: 20px;
    opacity: 0.5;
    overflow: hidden;
  }
  100% {
    opacity: 0;
    height: 0;
    overflow: hidden;
    z-index: -1;
    position: relative;
  }
}
@keyframes appear {
  0% {
    //height: auto;
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
