@import "../core/colors.scss";
@import "../core/fonts.scss";

select {
  @extend %extrasmallInterRegular;
  height: 54px;
}
.select label {
  @extend %xxsInterMedium;
}
.radiobutton.check-style.form-input {
  // width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
  padding-top: 50px;
}

.radiobutton.check-style.form-input p {
  position: absolute;
  top: 20px;
  font-size: 11px;
  font-weight: bold;
  color: #a0a0a0;
}

// .card-input-element + .card-input.card-input {
//   border-radius: 4px;
//   border: 1px solid #cfcfcf;
//   padding: 20px;
//   font-weight: normal;
//   color: #111;
// }

.card-input-element:checked + .card-input.card-input,
.card-input-element.disabled + .card-input.card-input {
  background: #fff;
  border: double 4px transparent;
  border-radius: 24px;
  background-image: linear-gradient(white, white),
                    linear-gradient(to top right, #FFD43C, #FF679B, #FF5C2F);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 0px 4px 4px rgba(102, 51, 78, 0.05);
}

.disabled + .card-input.card-input .panel-heading {
  padding-left: 0;
}

.margin-top {
  margin-top: 10px;
}

.margin-bottom {
  margin-top: 10px;
}

.form-input label {
  width: 100%;
}
.card-input-element {
  display: none;
}

.card-input {
  margin: 10px;
  padding: 00px;
}

.card-input:hover {
  cursor: pointer;
}

.card-input-element + .card-input.card-input {
  position: relative;
  border: 1px solid rgba(56, 27, 42, 0.1);
  box-shadow: 0px 4px 4px rgb(102 51 78 / 5%);
  border-radius: 24px;
  //padding: 16px 24px;
  background-color: white;
}

// .card-input-element:checked + .card-input.card-input:before {
//   background-color: $fun-blue;
//   border: 1px solid #fff;
//   box-shadow: 0 0 0px 2px $fun-blue;
//   height: 14px;
//   width: 14px;
// }

.card-input-element.disabled + .card-input.card-input:before {
  display: none;
}

.select.form-input p {
  @extend %extrasmallInterRegular;
  margin-bottom: 10px;
  color: #707070;
}

.select.form-input {
  width: 33%;
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
  }

  select::-ms-expand {
    display: none;
  }
}
.select-box {
  background-image: url("/assets/images/icons/down.svg") !important;
  background-repeat: no-repeat !important;
  background-position: right 10px center !important;
}

.select.form-input .select-box {
  border: 1px solid #cfcfcf;
  border-radius: 4px;
  padding: 16px;
  background: #fff;
  width: 100%;
  font-size: 14px;
  select {
    background: no-repeat;
    border: none;
    width: 100%;
    box-shadow: none;
    outline: 0;
  }
}

.form-input label:nth-child(2) .card-input.card-input {
  margin-left: 0;
}

.form-input label:nth-child(3) .card-input.card-input {
  margin-right: 0;
}

.card-input-element.disabled + .card-input.card-input:before {
  display: none;
}

.card-input-element.disabled + .card-input.card-input {
  padding-left: 20px;
  background: #fff;
  color: #000;
}

// FORM

.form-input.input {
  display: flex;
  flex-direction: column;
}

.form-input label {
  font-size: 11px;
  //font-weight: bold;
  color: #a0a0a0;
  margin-bottom: 10px;
}

// .form-input label + input {
//   border-radius: 4px;
//   border: 1px solid #cfcfcf;
//   padding: 15px;
//   font-size: 14px;
//   box-shadow: none;
//   outline: none;
// }

// .form-input label + input::placeholder {
//   opacity: 0.7;
// }

ul.list-check {
  flex-direction: column;
  li {
    padding: 5px;
    //font-size: 14px;
    //color: #414141;
    position: relative;
    padding-left: 25px;
    &:before {
      content: "";
      display: inline-block;
      vertical-align: middle;
      width: 15px;
      height: 15px;
      background: url("/assets/images/img/ico-check.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-position: center;
      position: absolute;
      left: 0;
    }
  }
}

/// SELECTBOX

.select.form-input p span {
  font-size: 14px;
  color: #111111;
}

// .select.form-input p span.mark.mark-orange {
//     width: 10px;
//     height: 10px;
//     display: inline-block;
//     border-radius: 50%;
//     background: orange;
//     margin-right: 10px;
// }

.select.form-input p.select-selected {
  background: url("/assets/images/img/ico-arrow-down.svg");
  background-position: right;
  background-repeat: no-repeat;
  background-size: 15px;
  margin: 0;
}

.select.form-input .select-box ul {
  list-style: none;
  position: absolute;
  background: #fff;
  width: calc(100% + 2px);
  left: -1px;
  border: 1px solid #cfcfcf;
  border-top: 0;
  border-radius: 0 0 4px 4px;
  z-index: 99;
  display: none;
  top: 98%;
}

.select.form-input .select-box {
  position: relative;
  padding: 15px 37px 15px 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.select.form-input .select-box ul li {
  font-size: 14px;
  cursor: pointer;
  padding: 15px;
  border-top: 1px solid #e7e7e7;
}

.select.form-input .select-box ul li:hover {
  background: rgba(0, 0, 0, 0.05);
}

.select.form-input .select-box.open ul {
  display: block;
}

// checkbox and radio

.form-input.checkbox,
.form-input.radio {
  margin-top: 20px;
  margin-bottom: 5px;
  /* Basic styles */
  input[type="checkbox"],
  input[type="radio"] {
    position: absolute;
    opacity: 0;
    z-index: -1;
  }
  label {
    position: relative;
    display: inline-block;
    padding: 0 0 0 30px;
    height: auto;
    line-height: 1.5;
    font-size: 14px;
    margin-bottom: 0;
    color: #707070;
    cursor: pointer;
  }
  label::before,
  label::after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 21px;
    height: 21px;
  }
  label::before {
    content: " ";
    border: 1px solid #66334E;
    border-radius: 20%;
    background-color: #fff;
    font-size: 11px;
    &:hover {
      background-color: #FFFBED;
    }
  }
  /* Checkbox */
  input[type="checkbox"] + label::after {
    // content: "\2714";
    content: "";
    // color: #2c3e50;
    width: 1.5em;
    height: 1.5em;
    background: url("/assets/icons/checked.svg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 4px;
    line-height: 1.5;
    text-align: center;
  }
  /* Radio */
  input[type="radio"] + label::before {
    border-radius: 50%;
  }
  input[type="radio"] + label::after {
    content: " ";
    top: 0.25em;
    left: 0.25em;
    width: 1em;
    height: 1em;
    background: #fff;
    border: 0.2em solid #2c3e50;
    border-radius: 50%;
  }
  /* :checked */
  input[type="checkbox"]:checked + label::before,
  input[type="radio"]:checked + label::before {
    background: #fff;
    border-color: #fff;
  }
  input[type="checkbox"] + label::after,
  input[type="radio"] + label::after {
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
  }
  input[type="checkbox"]:checked + label::after,
  input[type="radio"]:checked + label::after {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  /* Transition */
  label::before,
  label::after {
    -webkit-transition: 0.25s all ease;
    -o-transition: 0.25s all ease;
    transition: 0.25s all ease;
  }
}
