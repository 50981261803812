.pac-container 	{
  font-family: var(--ion-font-family) !important;
  box-shadow: none !important;
  border-radius: 0 0 4px 4px !important;
  border: 1px solid #ccc !important;
  .pac-item {
    padding: 8px 10px !important;
    line-height: 17x !important;
    font-size: 14px !important;
    .pac-icon  {
      display: none !important;
    }
  }
  .pac-item:hover {
    background-color: #ebf5ff !important;
  }
  .pac-item-query {
    font-size: 14px !important;
  }
}