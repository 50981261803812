@import "../../sass/core/fonts.scss";
@import "../../sass/core/colors.scss";


ul.pagination-tab {
    width: 100%;
    display: flex;
    list-style: none;
    justify-content: space-between;
    //border: 1px solid #E7E7E7;
    border-radius: 4px;
    margin-bottom: 30px;
    overflow: hidden;
    li {
        display: flex;
        padding: 20px 15px;
        width: 100%;
        // color: $fun-020;
        // border-left: 1px solid #E7E7E7;
        // font-weight: bold;
        //font-size: 16px;
        //align-items: center;

        flex-direction: column;
        justify-content: center;
        text-align: left;
        align-items: flex-start;
        position: relative;
        padding-left: 55px;
        span.number {
            text-align: center;
            width: 30px;
            height: 30px;
            border: 2px solid;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 10px;
            position: absolute;
            left: 15px;
            top: calc(50% - 15px);
        }

        &:first-child {
            border: 0;
        }

        &.selected {
            //background-color: #2E7EFF;
            .title {
                background: linear-gradient(45deg, #FF7C92 0%, #FF5F3E 48.7%, #FF68C5 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
            }
            .number {
                color: white;
                background: linear-gradient(45deg, #FF7C92 0%, #FF5F3E 48.7%, #FF68C5 100%);
            }
        }
        &.done {
            // flex-direction: column;
            // justify-content: center;
            // text-align: left;
            // align-items: flex-start;
            // position: relative;
            // padding-left: 55px;
            // span.number {
            //     color: #2FDA90;
            //     font-size: 0;
            //     background: url('/assets/images/img/ico-check-green.svg');
            //     background-position: center;
            //     background-repeat: no-repeat;
            //     background-position: center;
            //     position: absolute;
            //     left: 15px;
            //     top: calc(50% - 15px);
            // }

            // span.title {
            //     color: #000;
            //     padding-bottom: 2px;
            // }
            // span.mark-orange {
            //     color: #000 !important;
            //     @extend %extrasmallInterMedium;
            // }
            // span.mark {
            //     @extend %verysmallInterMedium;
            //     //font-size: 14px;
            //     //font-weight: normal;
            //     //color: #000;
            //     &-orange:before {
            //         content: "";
            //         width: 8px;
            //         height: 8px;
            //         background: orange;
            //         display: inline-block;
            //         border-radius: 50%;
            //         margin-right: 10px;
            //     }
            // }

        }
    }
    li.done {
        //color: $fun-080 !important;
    }

}

span.mark {
    @extend %verysmallInterMedium;
    //color: #000;
    //font-weight: normal;
    //font-size: 14px;
    &:before {
        content: "";
        //width: 8px;
        height: 8px;
        display: inline-block;
        border-radius: 50%;
        //margin-right: 5px;
    }
    &-orange:before {
        background: #FEA100;
    }
    &-blue:before {
        background: #0ACDD9;
    }
    &-null:before {
        display: none;
    }
}

@media (max-width: 991px) {
    html body ul.pagination-tab.show-all li {
        display: flex;
        border-bottom: 1px solid #e7e7e7;
    }

    html body ul.pagination-tab.show-all {
        flex-direction: column;
    }

}

