.message {
    background-color: rgba(#00BF40,0.04);
    display: flex;
    padding: 15px 20px;
    justify-content: space-between;
    border-radius: 4px;
    align-items: center;
    span.message-text {
        color: #00BF40;
        font-size: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
            margin-right: 10px;
        }
        b {
            margin: 0 5px;
        }
    }
    button {
        padding: 12px 32px;
    }
    &-orange {
        background-color: rgba(#FFBF52, 0.04);
        span.message-text {
            color: #FFBF52;
            svg path {
                fill: #FFBF52;
            }
        }
        button.default {
            background-color: #FFBF52;
        }
    }
}

@media (max-width: 991px) {
    .message {
        flex-direction: column;
        align-items: end;
        padding-left: 50px;
        position: relative;
    }
    
    .message span.message-text {
        display: block;
        margin-bottom: 15px;
    }
    
    .message span.message-text svg {
        position: absolute;
        left: 20px;
        top: 20px;
    }
}