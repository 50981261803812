@import "../core/colors.scss";
@import "../core/fonts.scss";


.bid {
    .content-background-modal {
        //height: calc(100vh - 400px);
        padding: 0 !important;
        height: 354px;
        //height: auto;
        display: flex;
        flex-direction: column;
        h3 {
            // @extend %verysmallInterBold;
            // font-size: 16px;
            // font-weight: bold;
            margin-bottom: 24px;
        }
        p {
            // font-size: 14px;
            // line-height: 1.5em;
            // color: $fun-080;
            // @extend %extrasmallInterRegular;
        }
        li {
            // @extend %extrasmallInterRegular;
            // color: $fun-060;
        }
        .content-boxed {
            //border: 1px solid $fun-010;
            border-collapse: collapse;
            .pagination-list {
                //height: 352px; Esto va a ir con media query solo para pantallas grandes
            }
            .col-6 {
                margin: 0 !important;
            }
            .col-6:first-child {
                border-right: 1px solid $fun-010;
            }
            .col-6:last-child {
                display: flex;
                justify-content: center;
                flex-direction: column;
                height: 352px;
                border-left: none;
                ul.list-check{
                    //display: flex;
                    //align-items: center;
                }
                .pagination-list {
                    //height: 100%;
                }
            }
        }
    }
    .digital-way {
        height: 445px;
        .content-boxed {
            .col-6:last-child, .pagination-list {
                height: 443px !important;
            }
        }
    }
}
.container-header {
	//border-top: 1px solid #e7e7e7;
    border-bottom: 1px solid $fun-010;
	margin-bottom: 34px;
	ul {
		list-style: none;
		display: flex;
		padding: 15px 0;
		li {
			display: flex;
			flex-direction: column;
			padding: 15px;
			width: 100%;
			&:first-child {
				border-right: 1px solid $fun-010;
				padding-left: 0;
				width: 150%;
			}
			// span:first-child {
            //     // font-size: 11px;
            //     @extend %xxsInterBold;
			// 	text-transform: uppercase;
			// 	font-weight: bold;
			// 	color: $fun-040;
			// 	margin-bottom: 7px;
            // }
            // span:last-child {
            //     @extend %verysmallInterMedium;
            // }
		}
	}
}

span.avatar {
    width: 24px;
    height: 24px;
    display: inline-flex;
    background: linear-gradient(54.81deg, #FF804D 17.58%, #FF8C92 47.6%, #FFCD3E 77.62%);
    justify-content: center;
    align-items: center;
    color: #fff!important;
    margin-right: 10px;
    border-radius: 50%;
}

@media only screen and (min-width: 991px) {
    .bid {
        .content-background-modal {
            .content-boxed {
                .pagination-list {
                    height: 352px;
                }
            }
        }
    }
  }
@media (max-width: 991px) {
    .bid {
        .content-background-modal {
            height: auto;
        }
        .digital-way {
            .content-boxed {
                .pagination-list {
                    // height: 600px !important;
                    height: auto !important;
                }
            }
        }
        .content-boxed {
            .col-6:last-child {
                height: auto !important;
                min-height: 32px !important;
            }
        }
    }
    .content-background-modal {
        .space {
            padding: 20px 24px !important;
        }
    }


    .container-header ul li:first-child {
        width: 100%;
        border: 0;
        display: flex;
    }

    .container-header ul li {
        display: none;
    }

    .container-header ul li:first-child span.hidden-lg {
        //color: $fun-060;
        margin-top: 5px;
    }

    .container-header ul li:first-child span:first-child {
        display: none;
    }

    .container-header ul.open {
        display: flex;
        flex-direction: column;
        position: absolute;
        z-index: 9999;
        background-color: #fff;
        top: 0;
        left: 0;
        width: 100%;
    }

    .container-header ul.open li {
        position: relative;
        display: flex;
        padding: 15px 0;
        flex-direction: row;
        justify-content: space-between;
    }

    .container-header {
        min-height: 103px;
        margin-top: 0;
    }

    .container-header .container {
        padding: 0;
    }

    .container-header .container ul {
        padding: 15px 20px;
        background-image: url('/assets/images/img/ico-arrow-down.svg');
        background-position: 93% 43px;
        background-repeat: no-repeat;
    }

    .container-header ul.open li:first-child {
        flex-direction: column;
    }

    .container-header ul.open + .overlay {
        width: 100vw;
        height: 100vh;
        background: #000;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 2;
        opacity: 0.5;
        display: block;
    }
}
@media only screen and (max-width: 480px) {
    .container {
      padding: 0 !important;
    }
}